<template>
	<section class="container">
		<div class="row">
			<div class="col-12">
				<BaseTable :items="employeeLogs" :fields="fields" :isBusy="isBusy">
					<template v-slot:cell(employeeLogs.timestamp)="data">
						{{ getDateAndTime(data.item.timestamp) }}
					</template>
					<template v-slot:cell(employeeLogs.operationType)="data">
						{{ data.item.operationType }}
					</template>
					<template v-slot:cell(employeeLogs.description)="data">
						{{ data.item.description }}
					</template>
					<template v-slot:cell(employeeLogs.person)="data">
						{{ data.item.person.email }}
					</template>

					<template #cell(data)="row">
						<b-button size="sm" @click="row.toggleDetails" class="mr-2">
							{{ row.detailsShowing ? $t('employeeLogs.hide') : $t('employeeLogs.show') }}
							{{ $t('employeeLogs.details') }}
						</b-button>
					</template>

					<template #row-details="row">
						<template v-for="(i, key) in row.item" v-bind="key">
							<b-card>
								<b>{{ key }}</b
								>: {{ i }}
							</b-card></template
						>
						<b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
					</template>
				</BaseTable>
			</div>
		</div>
		<!-- {{ employeeLogs }} -->
	</section>
</template>
<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
export default {
	name: 'employeeLogs',
	components: {
		BaseTable,
	},
	mixins: [dateMixin],
	async created() {
		this.$parent.$emit('updateLoader')
		this.isBusy = true
		try {
			await this.$store.dispatch('employeeVuex/getEmployeeLogs', this.employeePolicyId)
		} catch (error) {
			this.toast('Fejl', 'Medarbejderen har ikke en aftale tilknyttet', false)
		}
		this.isBusy = false
	},
	data() {
		return {
			disabled: true,
			clicked: false,
			isBusy: false,
			fields: [
				{
					key: 'employeeLogs.timestamp',
					label: i18n.t('employeeLogs.Timestamp'),
				},
				{
					key: 'employeeLogs.operationType',
					label: i18n.t('employeeLogs.operationType'),
					sortable: true,
				},
				{
					key: 'employeeLogs.description',
					label: i18n.t('employeeLogs.description'),
					sortable: true,
				},
				{
					key: 'employeeLogs.person',
					label: i18n.t('employeeLogs.person'),
					sortable: true,
				},
				{
					key: 'data',
					label: i18n.t('employeeLogs.data'),
					sortable: true,
				},
			],
		}
	},
	computed: {
		employeeLogs() {
			const logs = this.$store.getters['employeeVuex/employeeLogs']
			return logs.sort((a, b) => {
				return b.timestamp - a.timestamp
			})
		},
		companyId() {
			return this.$route.params.companyId
		},
		employeePolicyId() {
			return this.$store.getters['employeeVuex/employeePolicyId']
		},
		company() {
			return this.$store.getters['employeeVuex/company']
		},
	},
	methods: {},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
</style>
